
import BagHead from './components/BagHead'
import BagNotify from './components/BagNotify'
import BagEmpty from './components/BagEmpty'
import BagGoods from './components/BagGoods'
import BagCard from './components/BagCard'
import BagCusRec from './components/BagCusRec'
import BagSafe from './components/BagSafe'
import BagAd from './components/BagAd'
import BagRec from './components/BagRec'
import BagSubmit from './components/BagSubmit'
import BagCoupon from './components/BagCoupon'
import BagViewed from './components/BagViewed'
import BagWish from './components/BagWish'
import OrderCoupon from './components/OrderCoupon'

export default {
    name: 'Bag',
    // layout: 'main-footer',
    components: {
        BagHead,
        BagNotify,
        BagEmpty,
        BagGoods,
        BagCard,
        BagCusRec,
        BagSafe,
        BagAd,
        BagRec,
        BagSubmit,
        BagCoupon,
        BagViewed,
        BagWish,
        OrderCoupon
    },
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            hasLogin: true,
            middleEmit: {},
            currency: {},
            goodsInfo: {},  // 所有商品信息
            goods: {        // 商品信息
                hasEmpty: true
            },
            amt: {},        // 下单信息
            edit: {         // 购物车编辑
                id: ''
            },
            cus: {},        // 与定制页联调数据
            referrer: {},
            loading:false,
            language:{},
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.referrer = from
            return true
        })
    },
    created() {
        this.initialSet()
    },
    mounted() {
        this.initialMountedSet()
    },
    methods: {
        expressPaypal(){
            let orderInfo = this.$refs.OrderCoupon.getOrderAmt()
            let cartIds = []
            orderInfo.orderAmt?.orderItems.map(res=>{
                cartIds.push(res.id)
            })
            let params = {
                couponSpuIds:orderInfo.suitSpu,
                // cartIds:orderInfo.orderAmt.spuId,
                cartIds:cartIds,
                orderFrom:"cart",
                orderItems:orderInfo.orderAmt.orderItems,
                redirectUrl:window.location.href,
            }
            if(orderInfo.checkInfo?.code){
                params.code = orderInfo.checkInfo.code
                params.couponAmount = orderInfo.checkInfo.couponDiscountAmount
            }
            params = JSON.stringify(params)
            let fbq = {
                content_category: 'product',
                content_type: 'product_group',
                content_ids: [],
                contents: [],
                num_items: 0,
            };
            orderInfo.orderAmt?.orderItems.map(res=>{
                fbq.content_ids.push(res.spuId)
                fbq.contents.push(res)
            })
            this.$api.order.createExpressPaypalOrder({data:params}).then(response=>{
                const res = response?.result || {};
                // fb
                this.$fnApi.buried.fbq({
                    type: 'InitiateCheckout',
                    params: {
                        currency: 'USD',
                        value: (res.totalAmt/100).toFixed(2),
                        ...fbq
                    }
                }, this)
                window.location.href = res.linkHref || res.redirect_url
            })
        },
        checkoutBtn(){
            this.loading = true
            this.$refs.BagSubmit.handleSubmit()
        },
        // 商品数据
        setGoodsData(obj) {
            this.goods = obj.goods
            this.amt = obj.amt
            this.goodsInfo = obj
        },
        // 顶部编辑
        setBagEditData(obj) {
            this.edit = obj
        },
        emitEditDelete() {
            const ref = this.$refs.BagGoods;
            ref && ref.handleDelete({
                id: this.edit.id
            })
        },
        // emit
        getBagAjax() { // 购物车数据
            const ref = this.$refs.BagGoods;
            ref && ref.getBagAjax()
        },
        setChooseProduct(obj) { // 选择购物车商品
            const ref = this.$refs.BagGoods;
            ref && ref.setChooseProduct(obj)
        },
        setCusData(obj) { // 定制数据
            this.cus = {...this.cus, ...obj?.popup, ...obj}
        },
        couponChange(obj) { // 优惠券变更
            const amtDetail = obj.amtDetail || {};
            if(!amtDetail.code) {
                this.amt = this.goodsInfo.amt
            } else {
                this.amt = amtDetail
            }
        },
        // SEO HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: this.$translate('Shopping Bag'),
                keywords: this.$translate('Shopping Bag'),
                description: this.$translate('Shopping Bag'),
                image: 'https://cdn2.selleroa.com/yfn-upload/review/1694078645122.png'
            });
            return {
                ...head
            }
        },
        // 初始化
        initialSet() {
            this.currency = this.$storage.get('currency') || {}
            this.language = this.$storage.get('language');
            this.middleEmit.fn = (fn, options, callback) => {
                this[fn] && this[fn](options)
            }
        },
        initialMountedSet() {
            this.hasLogin = this.$login()
        }
    },
}
